@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');
/*   font-family: "Kalam", cursive; */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* font-family: "Montserrat", sans-serif; */

@font-face {
    font-family: 'jokerman';
    src: url('../Fonts/Jokerman-Regular.ttf');
}

:root {
    --green: #8DA21D;
    --dark-green: #607415;
    --purple: #755099;
    --bg-gray: #1F1E31;
}

body {
    color: #fff;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: #171B16;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
}

* {
    font-family: "Kalam", cursive;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: unset;
}

p {
    margin-bottom: 10px;
}

img {
    max-width: 100%;
    object-fit: cover;
}

ul {
    list-style-type: disc;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}