@media screen and (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }

  .partnercarousel .splide__slide {
    padding: 0 5%;
  }

  .aboutTxt {
    padding: 80px 18%;
  }

  .tokenImg img {
    width: 80%;
  }

  .buyTabs .nav-tabs {
    width: 22%;
  }

  .buyTabs .nav-tabs .nav-link {
    padding: 12px 25px;
  }
}

@media screen and (max-width: 1399px) {
  .aboutSec .mainTitle {
    font-size: 45px;
  }
}

@media screen and (max-width: 1199px) {
  .bannerTitle {
    font-size: 56px;
  }

  .bannerTxt p {
    font-size: 18px;
  }

  .aboutTxt {
    padding: 45px 80px;
  }

  .communityLinks a {
    font-size: 20px;
    width: 175px;
  }

  .buyTabs .nav-tabs .nav-link {
    padding: 10px 25px;
  }

  .buyContent h3 {
    font-size: 30px;
  }

  .buyTabs .tab-content {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {

  .bannerTitle,
  .mainTitle {
    font-size: 46px;
  }

  .socialDiv {
    bottom: -20px;
  }

  .socialDiv h4 {
    font-size: 20px;
  }

  .socialLinks a {
    width: 60px;
    height: 45px;
    line-height: 45px;
  }

  .socialLinks a img {
    width: 45%;
  }

  .aboutSec::after {
    background-size: contain;
    background-position: top;
  }

  .aboutTxt {
    padding: 45px 90px;
    text-align: center;
  }

  .contractAddress {
    font-size: 18px;
    padding: 15px;
  }

  .buyTabs .nav-tabs {
    width: 16%;
  }

  .buyTabs .nav-tabs .nav-link {
    font-size: 18px;
    border-radius: 12px;
  }

  .buyContent h3 {
    font-size: 26px;
  }

  .buyTabs .tab-content {
    font-size: 17px;
  }

  .buyContent .contractAddress {
    font-size: 14px;
  }

  .buyContent .contractAddress .btn {
    font-size: 13px;
    padding: 2px 12px;
  }

  .buyContent .buyNum {
    font-size: 50px;
    top: -30px;
  }

  .buySec::before {
    width: 14%;
  }

  .buySec .buyTabs .tab-content::after {
    width: 17%;
    right: -17%;
  }

  .communityLinks a {
    font-size: 18px;
    width: 160px;
  }

  .communityLinks a img {
    width: 27px;
  }
}

@media screen and (max-width: 767px) {
  .socialDiv {
    position: static;
    transform: none;
    margin-top: 20px;
  }

  .bannerImg img {
    transform: translateX(-35px);
  }

  .aboutTxt {
    font-size: 18px;
    padding: 45px;
    background-size: 100% 100%;
  }

  .aboutSec .mainTitle {
    font-size: 42px;
  }

  .contractAddress {
    font-size: 14px;
    padding: 10px;
    box-shadow: 0px -6px 0px var(--dark-green) inset;
  }

  .contractAddress .btn {
    padding: 5px 14px;
    font-size: 14px;
  }

  .buyTabs .nav-tabs {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .buyTabs .nav-tabs .nav-link {
    font-size: 16px;
  }

  .buyTabs .tab-content {
    font-size: 16px;
    margin-top: 20px;
    padding-top: 30px;
  }

  .buyContent .buyNum {
    font-size: 40px;
    top: -25px;
  }

  .buyContent h3 {
    font-size: 24px;
  }

  .communityLinks a {
    font-size: 16px;
    width: 140px;
  }

  .communityLinks a img {
    width: 23px;
  }
}

@media screen and (max-width: 575px) {

  .bannerTitle,
  .mainTitle {
    font-size: 40px;
  }

  .partnerSec {
    background-size: cover;
  }

  .tokenSec,
  .buySec {
    padding: 60px 0;
  }

  .tokenSec::after {
    background-size: contain;
    background-position: center 70px;
  }

  .partnercarousel {
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0px -5px 0px var(--dark-green) inset;
  }

  .partnercarousel .splide__slide {
    padding: 0 8%;
  }

  .aboutTxt {
    font-size: 16px;
    padding: 30px;
  }

  .aboutSec .mainTitle,
  .communitySec .mainTitle {
    font-size: 34px;
  }

  .availableDiv,
  .tokenDiv,
  .tokenImg {
    max-width: 300px;
    margin: auto;
  }

  .contractAddress span {
    display: block;
  }

  .buyTabs .nav-tabs .nav-link {
    padding: 10px;
  }

  .buyTabs .buyContent {
    text-align: center;
  }

  .buyContent .contractAddress {
    margin: auto;
    padding: 8px 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  .partnercarousel .splide__slide {
    padding: 0 5%;
  }

  .contractAddress span {
    font-size: 10px;
  }
}