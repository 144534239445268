/* bannerSec start */
.bannerSec {
  /* background: url('../Images/Backgrounds/bannerBg.png') no-repeat center center;
  background-size: cover; */
  padding: 150px 0 30px;
}

.bannerSec::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: url('../Images/Backgrounds/bannerBg.png') no-repeat center center;
  background-size: cover;
  mix-blend-mode: lighten;
}

.bannerTitle {
  font-family: 'jokerman';
  font-size: 64px;
}

.bannerTxt p {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 200;
}

.bannerImg img {
  transform: translateX(-35px);
}

.socialDiv {
  position: absolute;
  top: 0;
  right: 0;
  /* left: 50%;
  transform: translateX(-50%);
  bottom: 0; */
  text-align: center;
  z-index: 1;
}

.socialLinks a {
  display: block;
  width: 70px;
  height: 55px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  line-height: 55px;
}

/* bannerSec end */

.mainTitle {
  font-family: 'jokerman';
  font-size: 56px;
  text-align: center;
  text-transform: capitalize;
}

/* partnerSec start */
.partnerSec {
  background: url('../Images/Backgrounds/partnerBg.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 70px 0;
}

.partnercarousel {
  background-color: var(--green);
  padding: 20px 0;
  border-radius: 20px;
  box-shadow: 0px -10px 0px var(--dark-green) inset;
}

.partnercarousel .splide__slide::after {
  content: '';
  display: block;
  height: 100%;
  width: 2px;
  background: linear-gradient(transparent, #607415, transparent);
  position: absolute;
  right: -1px;
}

.partnercarousel .splide__slide {
  padding: 0 4%;
}

/* partnerSec end */

/* aboutSec start */
.aboutSec::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: url('../Images/Backgrounds/aboutBg.png') no-repeat center center;
  background-size: 100% 100%;
  mix-blend-mode: overlay;
}

.aboutTxt {
  background: url('../Images/Backgrounds/aboutTxtBg.png') no-repeat center center;
  background-size: contain;
  padding: 80px 90px;
  font-size: 20px;
}

/* aboutSec end */

/* availableSec start */
.availableSec {
  background: url('../Images/Backgrounds/availableBg.png') no-repeat center center;
  background-size: cover;
}

.availableDiv .availImg {
  position: absolute;
  left: 56%;
  transform: translate(-50%, -50%);
  top: 70%;
  max-width: 55%;
  max-height: 25%;
}

/* availableSec end */

/* tokenSec start */
.tokenSec {
  padding: 100px 0;
}

.tokenSec::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: url('../Images/Backgrounds/tokenBg.png') no-repeat center center;
  background-size: 100% 100%;
  mix-blend-mode: overlay;
}

.contractAddress {
  width: fit-content;
  margin: auto;
  background: var(--green);
  box-shadow: 0px -10px 0px var(--dark-green) inset;
  border-radius: 20px;
  text-align: center;
  padding: 25px 60px;
  font-size: 20px;
  word-break: break-all;
}

.contractAddress .btn {
  background-color: #171B16;
  border: none !important;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 18px;
}

.tokenDiv .tokenTxt {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.tokenDiv img {
  display: block;
  width: 100%;
  /* max-width: 300px; */
  margin: auto;
}

/* tokenSec end */

/* buySec start */
.buySec {
  padding: 100px 0;
}

.buySec::before,
.buySec .buyTabs .tab-content::after {
  content: '';
  display: block;
  width: 10%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: url('../Images/Backgrounds/buyBg.png') no-repeat;
  background-size: contain;
}

.buyTabs .nav-tabs {
  flex-direction: column;
  border: none;
  width: 22%;
  gap: 12px;
}

.buyTabs .nav-tabs .nav-link {
  width: 100%;
  min-width: 70px;
  background-color: var(--green);
  box-shadow: 0px -7px 0px var(--dark-green) inset;
  border: none;
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: left;
}

.buyTabs .nav-tabs .nav-item.show .nav-link,
.buyTabs .nav-tabs .nav-link.active {
  color: var(--green);
  background-color: #fff;
  box-shadow: 0px -7px 0px #b7b7b7 inset;
}

.buyTabs .tab-content {
  flex: 1;
  position: relative;
  background-color: #fff;
  box-shadow: 0px -7px 0px #b7b7b7 inset;
  border-radius: 20px;
  padding: 24px;
  color: #000;
  font-size: 20px;
}

.buySec .buyTabs .tab-content::after {
  width: 13%;
  left: unset;
  right: -13%;
  transform: rotateX(180deg);
}

.buyTabs .tab-content>.tab-pane {
  height: 100%;
}

.buyTabs .buyContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buyContent .buyNum {
  font-size: 62px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 5px 0px #7f7f7f;
  position: absolute;
  top: -35px;
  right: 6%;
}

.buyContent h3 {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
}

.buyContent .contractAddress {
  padding: 8px 15px;
  box-shadow: none;
  font-size: 16px;
  color: #fff;
  margin: 0;
  border-radius: 50px;
}

.buyContent .contractAddress .btn {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px;
  padding: 3px 17px;
}

/* buySec end */

/* communitySec start */
.communitySec {
  background-color: var(--green);
  border-bottom: 2px solid #fff;
}

.communityLinks a {
  display: block;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
  border-radius: 20px;
  width: 190px;
  text-align: center;
}

/* communitySec end */