/* header start */
.mainNavbar {
  width: 100%;
  background: linear-gradient(transparent, #00000070);
  /* background: #0000009c; */
  box-shadow: 0 2px 6px #242724;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 20px;
  left: 0;
  z-index: 99;
}

.mainNavbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 22px;
  padding: 30px 30px;
  border-radius: 10px;
}

/* .mainNavbar .navbar-nav .nav-link.active, */
.mainNavbar .navbar-nav .nav-link.active2 {
  /* background: radial-gradient(circle at 50% 0%, #64DD17 10%, transparent 10%), radial-gradient(at 50% 0%, #ffffff55 70%, var(--green) 70%), #000; */
  /* background-color: var(--green); */
  background: url('../Images//Backgrounds/navBg.png') no-repeat center center;
  background-size: cover;
  position: relative;
}

/* .mainNavbar .navbar-nav .nav-link.active::after, */
.mainNavbar .navbar-nav .nav-link.active2::after {
  content: '';
  width: 90%;
  height: 30%;
  display: block;
  border-radius: 50%;
  box-shadow: 0 6px 6px #B0CF0B;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.mainNavbar .navbar-brand img {
  max-width: 210px;
}

.themeBtn,
.themeBtn:hover {
  background: var(--green);
  border: 2px solid #000;
  /* border-width: 2px 0 0 2px; */
  font-size: 24px;
  border-radius: 20px;
  padding: 10px 30px;
  box-shadow: -5px -5px 0px var(--dark-green) inset;
}

.themeBtn.headerBtn {
  padding: 10px 25px;
}

.dexBtn,
.dexBtn:hover {
  background: #fff;
  box-shadow: -5px -5px 0px #0000002e inset;
}

.dexBtn img {
  width: 120px;
}

/* header end */

/* footerSec start */
.footerSec {
  text-align: center;
  font-size: 24px;
}

/* footerSec end */

@media screen and (max-width: 1399px) {
  .mainNavbar .navbar-nav .nav-link {
    padding: 30px 20px;
    font-size: 20px;
  }

  .dexBtn img {
    width: 100px;
  }

  .dexBtn,
  .themeBtn.headerBtn {
    padding: 10px 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .mainNavbar .navbar-brand img {
    max-width: 150px;
  }

  .mainNavbar .navbar-nav .nav-link {
    padding: 18px 10px;
    font-size: 18px;
  }

  .themeBtn {
    font-size: 20px;
    padding: 10px 25px;
  }

  .footerSec {
    font-size: 22px;
  }
}

@media screen and (max-width: 991px) {
  .mainNavbar .navbar-toggler {
    filter: brightness(0) invert(1);
    box-shadow: none;
  }

  .mainNavbar .navbar-nav .nav-link {
    padding: 10px;
  }

  /* .mainNavbar .navbar-nav .nav-link.active, */
  .mainNavbar .navbar-nav .nav-link.active2 {
    background: none;
  }

  /* .mainNavbar .navbar-nav .nav-link.active::after, */
  .mainNavbar .navbar-nav .nav-link.active2::after {
    display: none;
  }

  .footerSec {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .mainNavbar .navbar-brand img {
    max-width: 165px;
  }

  .footerSec {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  .mainNavbar .navbar-nav .nav-link {
    padding: 7px;
    font-size: 16px;
  }

  .themeBtn {
    font-size: 18px;
    padding: 7px 20px;
    border-radius: 15px;
  }

  .footerSec {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {}